import React, { Component, createRef } from "react";
import { connect } from "react-redux";

import { setSubheaderText } from "../actions/navigateActions";
import ErrorIcon from "@material-ui/icons/Error";
import API from "../utilities/LocalApiProxy";

class DocumentCompare extends Component {
	constructor(props) {
		super(props);
		this.state = {
			encounteredDraftableError: false, // if true the iframe will be replaced with a custom error message
		};
		this.timeoutRef = createRef();
		this.handleDraftableError = this.handleDraftableError.bind(this);
	}

	componentDidMount() {
		this.props.setSubheaderText("Document Compare");
	}

	clearTimeout = () => {
		if (this.timeoutRef.current) {
			clearTimeout(this.timeoutRef.current); // Clear the timeout using the stored ID
			this.timeoutRef.current = null; // Reset the ref
		}
	};

	componentWillUnmount() {
		this.clearTimeout();
	}

	handleDraftableError = () => {
		this.timeoutRef.current = setTimeout(() => {
			API.post("/document/compare/status", {
				body: {
					draftableComparisonIdentifier:
						this.props.draftableComparisonIdentifier,
				},
			})
				.then((res) => {
					if (res.mysql.comparisonFailed) {
						// the comparison failed. Show custom error message
						this.setState({
							encounteredDraftableError: true,
						});
					} else if (res.mysql.shouldCallAgain) {
						// comparison not ready yet, will check again in 10 seconds
						this.handleDraftableError();
					} else {
						// comparison was a success, and is likely already being viewed
					}
				})
				.catch((err) => {
					console.error(err);
				});
		}, 10000);
	};

	render() {
		return (
			<>
				{this.props.inlineCompareUrl ? (
					<div
						style={{
							height: "100%",
							// marginTop: 0,
							// marginBottom: 0,
						}}
					>
						{/* 
							If the comparison works correctly, the iframe will load the comparison
						*/}
						{!this.state.encounteredDraftableError && (
							<iframe
								src={this.props.inlineCompareUrl}
								style={{
									width: "100%",
									height: "100%",
									border: "1px solid #e0e0e0",
								}}
								allowFullScreen
								id={"inlineCompare"}
								onLoad={() => {
									if (!this.state.encounteredDraftableError) {
										this.handleDraftableError();
									}
								}}
							/>
						)}
						{/* if an error is encountered we will show this custom error message */}
						{this.state.encounteredDraftableError && (
							<div
								style={{
									borderLeft: "7px solid red",
									marginLeft: 10,
									paddingLeft: 10,
								}}
							>
								<div
									style={{
										display: "flex",
										columnGap: 15,
										placeItems: "center",
									}}
								>
									<ErrorIcon color="error" />

									<h2
										style={{
											marginTop: 10,
											marginBottom: 10,
											color: "red",
										}}
									>
										Comparison Failed to Load
									</h2>
								</div>
								<div style={{ display: "flex" }}>
									<div style={{ marginLeft: 10 }}>
										<div
											style={{
												fontWeight: 900,
												marginBottom: 15,
												fontSize: "1.2em",
											}}
										>
											Error in Draftable Software
										</div>
										<div>
											We&apos;re sorry, there was an error
											trying to load these documents for
											comparison.
										</div>
										<p>
											LexWorkplace&apos;s inline document
											compare is powered by{" "}
											<a href="https://www.draftable.com/draftable-legal">
												Draftable
											</a>
											, which failed to load your
											documents.
										</p>
										<div>
											Please try again later. If the
											problem persists, please contact{" "}
											<a href="https://help.lexworkplace.com/">
												LexWorkplace Support
											</a>
											.
										</div>
									</div>
								</div>
							</div>
						)}
					</div>
				) : (
					<div>Comparison not found</div>
				)}
			</>
		);
	}
}
const mapStateToProps = (state) => ({
	inlineCompareUrl: state.browserLocalstorage.inlineCompareUrl,
	draftableComparisonIdentifier:
		state.browserLocalstorage.draftableComparisonIdentifier,
});
export default connect(mapStateToProps, { setSubheaderText })(DocumentCompare);
